import { animate, spring } from "motion";
import {
  Show,
  createEffect,
  createSignal,
  onMount,
  Switch,
  Match,
} from "solid-js";
import { greenCheck, hubbleAssured } from "~/assets/assets";
import { VoucherCardField } from "~/components/brand_order/voucher_card_field";
import { Divider } from "~/widgets/divider";
import { PhosphorIcon } from "~/widgets/icons";
import { Spacer } from "~/widgets/spacer";
import { UseOnBrandButton } from "~/widgets/use_on_brand_button";
import { zIndexes } from "~/z_indexes";
import { HubbleAssuredDetails } from "../brand/assured_details";
import { SecondaryCtas } from "../brand/secondary_ctas";
import { VoucherGenerationBrandCard } from "../brand_l2/voucher_generation_brand_card";
import { useModal } from "../modal";
import { HowToUseSteps } from "~/server/types/brand";
import { VoucherRedemptionType } from "~/server/types/order";
import { formatDate } from "~/utils/date";

type CardReadySectionHeaderProps = {
  amount: number;
};

export function CardReadySectionHeader(props: CardReadySectionHeaderProps) {
  onMount(() => {
    const element = document.querySelector(".anim-order-success-check")!;
    animate(
      element,
      {
        opacity: [0, 0.5, 1],
        scale: [0, 1.2, 1],
        rotate: 360,
      },
      { easing: spring() }
    );
  });

  return (
    <div class="flex flex-col items-center justify-center lg:items-start">
      <div
        class="h-[56px] w-[56px]"
        style={{
          "z-index": zIndexes.orderSuccessHeaderTick,
        }}
      >
        <img
          class="anim-order-success-check h-[56px] w-[56px]"
          src={greenCheck}
          alt="brand card"
        />
      </div>
      <Spacer height={20} />
      <div class="text-sm font-['Inter'] font-bold uppercase leading-tight tracking-tight text-green-700">
        gift card worth ₹{props.amount}
      </div>
      <Spacer height={8} />
      <div class="font-['Inter'] text-[32px] font-black uppercase leading-[38.40px] tracking-wider text-green-700">
        Ready to use!
      </div>
    </div>
  );
}

type CardReadySectionVoucherProps = {
  brandId: string;
  brandName: string;
  amount: number;
  voucherRedemptionType: VoucherRedemptionType;
  cardNumber?: string;
  pin?: string;
  deeplink: string;
  tncResId: string;
  date: string;
  isAssured: boolean;
  plainLogoUrl: string;
  cardBackgroundColor: string;
  showHubbleLogoOnCard: boolean;
  shareText: string;
};

export function CardReadySectionVoucher(props: CardReadySectionVoucherProps) {
  const [copied, setCopied] = createSignal<boolean>(false);

  createEffect(() => {
    copied();

    setTimeout(() => {
      setCopied(false);
    }, 1000);
  });

  return (
    <div class="w-full rounded-[32px] bg-[#EDEEF1] px-3 py-3">
      <div class="flex flex-row items-end justify-between px-3 pb-[8px]">
        <div class="bottom-0 text-small text-basePrimaryDark lg:text-mediumBold">
          {formatDate(new Date(`${props.date}Z`))}
        </div>
        <Show when={props.isAssured}>
          <AssuredTag />
        </Show>
      </div>
      <div class="flex h-fit w-full flex-col items-start justify-start gap-3 rounded-[28px] border border-neutral-200 bg-white p-4 shadow">
        <VoucherGenerationBrandCard
          showAmount={true}
          amount={props.amount}
          plainLogoUrl={props.plainLogoUrl}
          cardBackgroundColor={props.cardBackgroundColor}
          showHubbleLogo={props.showHubbleLogoOnCard}
        />
        <div class="flex flex-col items-start justify-start gap-3 self-stretch px-2">
          <Switch>
            <Match
              when={props.voucherRedemptionType === "CARD_AND_PIN_NO_SECURED"}
            >
              <VoucherCardField
                fieldName="Card no."
                fieldValue={props.cardNumber!}
              />
              <Divider />
              <VoucherCardField fieldName="Pin" fieldValue={props.pin!} />
            </Match>
            <Match when={props.voucherRedemptionType === "CARD_NUMBER_SECURED"}>
              <VoucherCardField
                fieldName="Card no."
                fieldValue={props.cardNumber!}
              />
            </Match>
            <Match when={props.voucherRedemptionType === "PIN_NO_SECURED"}>
              <VoucherCardField fieldName="Pin" fieldValue={props.pin!} />
            </Match>
          </Switch>
        </div>
        <Show when={props.deeplink}>
          <div class="flex w-full flex-row items-center gap-3 ">
            <UseOnBrandButton
              deeplink={props.deeplink}
              brandName={props.brandName}
            />
            <a
              href={`https://wa.me/?text=${encodeURIComponent(props.shareText)}`}
              data-action="share/whatsapp/share"
            >
              <div class="flex cursor-pointer  items-center justify-center rounded-full  border border-neutral-200 bg-white p-[10px]  shadow-sm">
                <PhosphorIcon name="share-network" class="text-black" />
              </div>
            </a>
          </div>
        </Show>
      </div>
      <Spacer height={12} />
      <SecondaryCtas brandId={props.brandId} tncResId={props.tncResId} />
    </div>
  );
}

function AssuredTag() {
  const { setIsModalOn, updateModalContentGetter } = useModal()!;

  return (
    <div
      onClick={() => {
        updateModalContentGetter(() => {
          return <HubbleAssuredDetails />;
        });

        setIsModalOn(true);
      }}
    >
      <div class="flex cursor-pointer flex-row items-center gap-1">
        <img
          class="h-5 w-5"
          src={hubbleAssured}
          alt="Hubble assured guarantee"
        />
        <span class="text-small text-basePrimaryDark lg:text-f12Bold lg:uppercase lg:text-textDark">
          <u>Hubble Assured</u>
        </span>
      </div>
    </div>
  );
}
